if (jQuery("body.page-template-conocenos")) {
  let accionesContainer, accionesContainerWidth, accionesContainerMargin, divOffset, leftArrow;
  window.addEventListener("load", function () {
    accionesContainer = document.querySelector(".actions-wrapper");
    accionesContainerWidth = accionesContainer.scrollWidth;
    accionesContainerMargin = parseFloat(window.getComputedStyle(accionesContainer).marginLeft);
    divOffset = accionesContainer.scrollLeft;
    leftArrow = document.querySelector("#btnLeftArrow");
    rightArrow = document.querySelector("#btnRightArrow");
  });
  function scrollRightAcciones() {
    setTimeout(() => {
      let completeWidth = Math.floor(accionesContainerWidth + accionesContainerMargin);
      let completedScroll = document.body.clientWidth + divOffset;
      /* console.log(
        "container: " +
          (accionesContainerWidth + accionesContainerMargin) +
          ", window: " +
          document.documentElement.clientWidth +
          ", offset: " +
          divOffset +
          ", completedScroll: " +
          completedScroll +
          ", completeWidth: " +
          completeWidth
      ); */
      if (divOffset > 0) {
        leftArrow.classList.add("show");
      }
      if (completedScroll >= completeWidth) {
        rightArrow.classList.remove("show");
      }
    }, 100);

    if (divOffset + document.body.clientWidth <= accionesContainerWidth + accionesContainerMargin) {
      divOffset += 400;
    }
    accionesContainer.scroll({ left: divOffset, behavior: "smooth" });
  }
  function scrollLeftAcciones() {
    if (divOffset >= 400) {
      divOffset -= 400;
    }
    let right = accionesContainer.scrollLeft;
    accionesContainer.scroll({ left: right - 400, behavior: "smooth" });
    if (right <= 400) {
      leftArrow.classList.remove("show");
      rightArrow.classList.add("show");
    } else if (!leftArrow.classList.contains("show")) {
      leftArrow.classList.add("show");
    }
  }
  window.scrollRightAcciones = scrollRightAcciones;
  window.scrollLeftAcciones = scrollLeftAcciones;
}

jQuery(document).ready(function() {

  var observer = new MutationObserver(function (mutationsList) {
    for (var mutation of mutationsList) {
      if (mutation.addedNodes && mutation.addedNodes.length) {
        for (var addedNode of mutation.addedNodes) {
          if (jQuery(addedNode).hasClass("cky-modal")) {
            checkConsentContainer();

            jQuery(".cky-consent-container, .cky-modal").on("click", function () {
              checkConsentContainer();
            });
          }
        }
      }
    }
  });

  observer.observe(document.body, { childList: true, subtree: true });

  checkConsentContainer();

  jQuery(".cky-consent-container, .cky-modal").on("click", function () {
    checkConsentContainer();
  });

});
